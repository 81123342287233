/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { getHost } from '../../services/EnvironmentService';
import { applyCredentials } from '../../services/AuthenticationService'
import { saveAs } from 'file-saver';
import {
    Button,
    Table,
    Row,
    Col, Card, CardHeader, CardBody,
    NavbarBrand,
    Navbar,
    Nav,
    UncontrolledCollapse,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap";
import Loader from 'react-loader-spinner'
import zip from 'jszip'
import JSZipUtils from 'jszip-utils'
import axios from 'axios'

function arrayBufferToString(buffer) {
    var byteArray = new Uint8Array(buffer);
    var str = "", cc = 0, numBytes = 0;
    for (var i = 0, len = byteArray.length; i < len; ++i) {
        var v = byteArray[i];
        if (numBytes > 0) {
            //2 bit determining that this is a tailing byte + 6 bit of payload
            if ((cc & 192) === 192) {
                //processing tailing-bytes
                cc = (cc << 6) | (v & 63);
            } else {
                throw new Error("this is no tailing-byte");
            }
        } else if (v < 128) {
            //single-byte
            numBytes = 1;
            cc = v;
        } else if (v < 192) {
            //these are tailing-bytes
            throw new Error("invalid byte, this is a tailing-byte")
        } else if (v < 224) {
            //3 bits of header + 5bits of payload
            numBytes = 2;
            cc = v & 31;
        } else if (v < 240) {
            //4 bits of header + 4bit of payload
            numBytes = 3;
            cc = v & 15;
        } else {
            //UTF-8 theoretically supports up to 8 bytes containing up to 42bit of payload
            //but JS can only handle 16bit.
            throw new Error("invalid encoding, value out of range")
        }

        if (--numBytes === 0) {
            str += String.fromCharCode(cc);
        }
    }
    if (numBytes) {
        throw new Error("the bytes don't sum up");
    }
    return str;
}

function zipFiles(data, range) {
    var jZip = new zip()
    const files = data.files.data;
    if (range) {
        var now = new Date()
        var offSet = (24 * 60 * 60 * 1000) * range
        var before = now.setTime(now.getTime() - offSet);
        const selected = files.filter(f => new Date(f.LastModified) >= before);
        var fetched = []
        let concatenated = ""
        for (let i = 0; i < selected.length; i++) {
            // eslint-disable-next-line no-loop-func
            JSZipUtils.getBinaryContent(`${getHost().S3_LOCATION}${selected[i].Key}`, function (err, data) {
                if (err) {
                    // ignore this error: just logging
                    // deferred.reject(zip); // or we may fail the download
                } else {
                    // if (i === 0) {
                    //     concatenated += arrayBufferToString(data);
                    // } else {
                        concatenated += arrayBufferToString(data).replace(/GOLDEN RECORD/g, '').replace(/ACN_CREATED_DT/g, '').replace(/TERM_DT_ACN/g, '').replace(/ROWID_SYSTEM/g, '').replace(/ACN NUMBER/g, '').replace(/MARKET_ID_ACN/g, '').replace(/FTN_FTN_GEO_ID_ACN/g, '').replace(/MARKET_ID_ACN/g, '').replace(/STD_OPERATIONAL_NAME/g, '').replace(/NAME_2/g, '').replace(/MARKET_ID_ACN/g, '').replace(/STORE NBR/g, '').replace(/STD_ADDR_LN1/g, '').replace(/STD_STREET_NAME/g, '').replace(/STD_HOUSE_NBR/g, '').replace(/STD_ZIP_CD/g, '').replace(/STD_CITY/g, '').replace(/STD_CITY/g, '').replace(/comm_val/g, '').replace(/STD_STATE_CD/g,'')
                    // }
                    jZip.file(keyToName(selected[i].Key), data, { binary: true });
                    fetched.push(selected[i])
                    generate();
                }
            });
        }
        function generate() {
            if (fetched.length === selected.length) {
                concatenated = `"GOLDEN RECORD","ACN_CREATED_DT","TERM_DT_ACN","ROWID_SYSTEM","ACN NUMBER","MARKET_ID_ACN","FTN_FTN_GEO_ID_ACN","STD_OPERATIONAL_NAME","NAME_2","STORE NBR","STD_ADDR_LN1","STD_STREET_NAME","STD_HOUSE_NBR","STD_ZIP_CD","STD_CITY","STD_STATE_CD"`+concatenated;
                concatenated = concatenated.replace(/"STD_STATE_CD"""/g,'"STD_STATE_CD"')
                jZip.generateAsync({ type: "blob" })
                    .then(function (content) {
                        var today = new Date();
                        var dd = String(today.getDate()).padStart(2, '0');
                        var mm = String(today.getMonth() + 1).padStart(2, '0');
                        var yyyy = today.getFullYear();
                        today = mm + '-' + dd + '-' + yyyy;
                        // see FileSaver.js
                        var blob = new Blob([concatenated], { type: "text/plain;charset=utf-8" });
                        if(content){
                            console.log("SAVE: "+selected.length)
                            saveAs(blob, `${today}.csv`);
                        }
                        // saveAs(content, `${today}.zip`);
                    });
            }else{
            }
        }

    } else {
        var fetch = []
        let concatenated = ""
        for (let i = 0; i < files.length; i++) {
            // eslint-disable-next-line no-loop-func
            JSZipUtils.getBinaryContent(`${getHost().S3_LOCATION}${files[i].Key}`, function (err, data) {
                if (err) {
                } else {

                    // if (i === 0) {
                    //     concatenated += arrayBufferToString(data);
                    // } else {
                        concatenated += arrayBufferToString(data).replace(/GOLDEN RECORD/g, '').replace(/ACN_CREATED_DT/g, '').replace(/TERM_DT_ACN/g, '').replace(/ROWID_SYSTEM/g, '').replace(/ACN NUMBER/g, '').replace(/MARKET_ID_ACN/g, '').replace(/FTN_FTN_GEO_ID_ACN/g, '').replace(/MARKET_ID_ACN/g, '').replace(/STD_OPERATIONAL_NAME/g, '').replace(/NAME_2/g, '').replace(/MARKET_ID_ACN/g, '').replace(/STORE NBR/g, '').replace(/STD_ADDR_LN1/g, '').replace(/STD_STREET_NAME/g, '').replace(/STD_HOUSE_NBR/g, '').replace(/STD_ZIP_CD/g, '').replace(/STD_CITY/g, '').replace(/STD_CITY/g, '').replace(/comm_val/g, '').replace(/STD_STATE_CD/g,'')
                    // }
                    jZip.file(keyToName(files[i].Key), data, { binary: true });
                    fetch.push(files[i])
                    gen()
                }
            });
        }
        function gen() {
            if (fetch.length === files.length) {
                concatenated = `"GOLDEN RECORD","ACN_CREATED_DT","TERM_DT_ACN","ROWID_SYSTEM","ACN NUMBER","MARKET_ID_ACN","FTN_FTN_GEO_ID_ACN","STD_OPERATIONAL_NAME","NAME_2","STORE NBR","STD_ADDR_LN1","STD_STREET_NAME","STD_HOUSE_NBR","STD_ZIP_CD","STD_CITY","STD_STATE_CD"`+concatenated;
                concatenated = concatenated.replace(/"STD_STATE_CD"""/g,'"STD_STATE_CD"')

                jZip.generateAsync({ type: "blob" })
                    .then(function (content) {

                        var today = new Date();
                        var dd = String(today.getDate()).padStart(2, '0');
                        var mm = String(today.getMonth() + 1).padStart(2, '0');
                        var yyyy = today.getFullYear();
                        today = mm + '-' + dd + '-' + yyyy;
                        // see FileSaver.js
                        var blob = new Blob([concatenated + `"`], { type: "text/plain;charset=utf-8" });
                        if(content){
                            console.log("SAVE: " +files.length)
                            saveAs(blob, `${today}.csv`);
                        }
                        // saveAs(content, `${today}.zip`);
                    });
            }
            else{
            }
        }
    }
}






function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    var ii = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, ii), 2) + ' ' + sizes[ii];
}
function keyToName(key) {
    return decodeURIComponent(key).split('/').pop();
}

const Menu = props => {
    const [navbarColor, setNavbarColor] = React.useState("navbar-default");
    const {
        data
    } = props;
    React.useEffect(() => {
        const updateNavbarColor = () => {
            setNavbarColor("#f40000");
        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    return (
        <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                MD Bottler Portal
  </NavbarBrand>
            <button
                aria-controls="navbarSupportedContent"
                aria-expanded={false}
                aria-label="Toggle navigation"
                className="navbar-toggler navbar-toggler-right"
                data-target="#navbar-menu"
                data-toggle="collapse"
                id="navbar-menu"
                type="button"
            >
                <span className="navbar-toggler-bar" />
                <span className="navbar-toggler-bar" />
                <span className="navbar-toggler-bar" />
            </button>
            <UncontrolledCollapse navbar toggler="#navbar-menu">
                <Nav className="mr-auto" navbar>
                    {/* <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                            href="#pablo"
                            id="dropdownMenuButton"
                            nav
                            onClick={e => e.preventDefault()}
                            role="button"
                        >
                            Clean Up
        </DropdownToggle>
                        <DropdownMenu
                            aria-labelledby="dropdownMenuButton"
                            className="dropdown-info"
                        >
                            <DropdownItem header tag="span">
                                Delete
          </DropdownItem>
                            <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                Older than a week
          </DropdownItem><DropdownItem divider />
                            <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                Older than a month
          </DropdownItem><DropdownItem divider />
                            <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                All
          </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>*/}
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle
                            aria-expanded={false}
                            aria-haspopup={true}
                            caret
                            color="default"
                            data-toggle="dropdown"
                            href="#pablo"
                            id="dropdownMenuButton"
                            nav
                            onClick={e => e.preventDefault()}
                            role="button"
                        >
                            Download
        </DropdownToggle>
                        <DropdownMenu
                            aria-labelledby="dropdownMenuButton"
                            className="dropdown-info"
                        >
                            <DropdownItem header tag="span">
                                Bulk
          </DropdownItem>
                            <DropdownItem
                                href="#"
                                onClick={e => zipFiles(data, 8)}
                            >
                                One Week
          </DropdownItem><DropdownItem divider />
                            <DropdownItem
                                href="#"
                                onClick={e => zipFiles(data, 31)}
                            >
                                One Month
          </DropdownItem><DropdownItem divider />
                            <DropdownItem
                                href="#"
                                onClick={e => zipFiles(data, undefined)}
                            >
                                All
          </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </UncontrolledCollapse>
        </Navbar>
    );
}

const Explorer = props => {
    const [data, setData] = useState({ files: [] });
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState(false);
    const {
        id_token,
    } = props;
    useEffect(() => {
        async function getConaData() {
            if (!sessionStorage.getItem('user') && !sessionStorage.getItem('user-uuid') && sessionStorage.getItem('token')) {
                await applyCredentials(id_token)
                try {
                    var result = await axios.get(`${getHost().API_ENDPOINT}?bottler=${sessionStorage.getItem('user')}&key=${sessionStorage.getItem('guap')}`);
                    setData({ files: result.data });
                    setLoading(false)
                    setValid(true)
                  } catch (err) {
                    setLoading(false)
                    setValid(false)
                  }
            } else {
                try {
                    var result = await axios.get(`${getHost().API_ENDPOINT}?bottler=${sessionStorage.getItem('user')}&key=${sessionStorage.getItem('guap')}`);
                    setData({ files: result.data });
                    setLoading(false)
                    setValid(true)
                  } catch (err) {
                    setLoading(false)
                    setValid(false)
                  }
            }
        }
        getConaData();
    }, [id_token]);


    async function getRefresh() {
        setLoading(true)
        try {
            var result = await axios.get(`${getHost().API_ENDPOINT}?bottler=${sessionStorage.getItem('user')}&key=${sessionStorage.getItem('guap')}`);
            setData({ files: result.data });
            setLoading(false)
            setValid(true)
          } catch (err) {
            setLoading(false)
            setValid(false)
          }
    }

    function setTable() {
        if (!valid) {
            return (
                <>
                    <h3 style={{textAlign:'center'}}>You have been authenticated but lack all the permissions required</h3>
                </>
                )
        }
        else if (valid && data && data.files && data.files.data.length > 0) {
            return (
                <>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Last Modified</th>
                                <th>Size</th>
                                <th>Download</th>
                                {/* <th>Delete</th> */}
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px' }}>
                            {data.files.data.map((item, key) => (
                                <tr key={key}>
                                    <th scope="row" style={{ fontWeight: 'unset', verticalAlign: 'middle' }}>{keyToName(item.Key)}</th>
                                    <td style={{ verticalAlign: 'middle' }}>{item.LastModified}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{bytesToSize(item.Size)}</td>
                                    <td>
                                        <Button
                                            className="btn-link"
                                            style={{ color: 'green', padding: '0' }}
                                            onClick={() => { window.open(`${getHost().S3_LOCATION}${item.Key}`) }}
                                            type="button"

                                        >
                                            Download
                                                    </Button></td>
                                    {/* <td>
                                                    <Button
                                                        className="btn-link"
                                                        style={{ color: '#f40000', padding: '0' }}
                                                        type="button"
                                                    >
                                                        Delete
                                         </Button>
                                                </td> */}
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </>
            )
        } else {
            return (
                <>
                 <h3 style={{textAlign:'center'}}>No Records found in the last 30 days</h3>
                </>
            )
        }

    }
    return (
        <>
            <Menu data={data} />
            <Row className="row" style={{ marginTop: `5%` }}>
                <Col lg={10} sm={10} style={{ margin: `0 auto` }}>
                    <Card style={{ minHeight: `700px` }}>
                        <CardHeader tag="h3" style={{ backgroundColor: "slategray", color: 'white' }} >File Broswer
                        <Button
                                onClick={() => {
                                    getRefresh()
                                }}
                                className="btn-round" color="white" outline style={{ float: 'right', borderColor: 'white' }}>
                                <i className="fa fa-refresh" /> Refresh
                        </Button>
                        </CardHeader>
                        <CardBody>
                            {!loading ? (
                                setTable()
                                // <Table hover>
                                //     <thead>
                                //         <tr>
                                //             <th>Name</th>
                                //             <th>Last Modified</th>
                                //             <th>Size</th>
                                //             <th>Download</th>
                                //         </tr>
                                //     </thead>
                                //     <tbody style={{ fontSize: '12px' }}>
                                //         {data.files.data.map((item, key) => (
                                //             <tr key={key}>
                                //                 <th scope="row" style={{ fontWeight: 'unset', verticalAlign: 'middle' }}>{keyToName(item.Key)}</th>
                                //                 <td style={{ verticalAlign: 'middle' }}>{item.LastModified}</td>
                                //                 <td style={{ verticalAlign: 'middle' }}>{bytesToSize(item.Size)}</td>
                                //                 <td>
                                //                     <Button
                                //                         className="btn-link"
                                //                         style={{ color: 'green', padding: '0' }}
                                //                         onClick={() => { window.open(`${getHost().S3_LOCATION}${item.Key}`) }}
                                //                         type="button"

                                //                     >
                                //                         Download
                                //                     </Button></td>

                                //             </tr>
                                //         ))}

                                //     </tbody>
                                // </Table>
                            ) : (
                                    <div style={{ margin: '25px auto', textAlign: 'center', }}>
                                        <Loader type="ThreeDots" color="slategray" height={40} width={80} />
                                    </div>
                                )}
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </>
    );
}

export default Explorer;
