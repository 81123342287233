/**
 * utility function to be called based on url and environment.
 */
const uuid = require('uuid');

//TODO: these should be configurable and not hardcoded
const CONFIG = {
  LOCAL: {
    ENDPOINT: 'http://localhost:3000',
    IDENTITY_POOL_URL : 'login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d',
    API_KEY: 'rGHZJsKRzxt9GNI3', 
    S3_LOCATION:'https://ccna-fab-cona-prod-transformation.s3-us-west-2.amazonaws.com/',
    API_ENDPOINT:'https://kep1ll9zhl.execute-api.us-west-2.amazonaws.com/prod/new',
    IDENTITY_POOL_ID: '',
    AUTH_PROVIDER_URL: `https://login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d/oauth2/authorize?client_id=47af3926-55c6-4718-a718-75bd35073dc8&response_type=id_token&redirect_uri=https://d147atg6dv2nig.cloudfront.net&response_mode=fragment&scope=openid%20email%20profile&state=12345&nonce=${uuid()}`,
    LOGOUT_URL: '',
    REGION: '',
    IDP_LOGIN: ''
  },
  NONPROD: {
    ENDPOINT: 'https://ccna-fab-cona-qa-transformation.s3-us-west-2.amazonaws.com/',
    IDENTITY_POOL_URL : 'login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d',
    API_KEY: '', 
    S3_LOCATION:'https://ccna-fab-cona-qa-transformation.s3-us-west-2.amazonaws.com/',
    API_ENDPOINT:'https://04hgz06tv6.execute-api.us-west-2.amazonaws.com/prod/new',
    IDENTITY_POOL_ID: '',
    AUTH_PROVIDER_URL: `https://login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d/oauth2/authorize?client_id=5d0d34dc-64e8-4b65-b9c1-7c87c650b707&response_type=id_token&&redirect_uri=https://qa.portal.mdm.ko.com&response_mode=fragment&scope=openid%20email%20profile&state=12345&nonce=${uuid()}`,
    LOGOUT_URL: '',
    REGION: '',
    IDP_LOGIN: ''
  },
  PROD: {
    ENDPOINT: 'https://ccna-fab-cona-prod-transformation.s3-us-west-2.amazonaws.com/',
    IDENTITY_POOL_URL : 'login.microsoftonline.com/548d26ab-8caa-49e1-97c2-a1b1a06cc39c',
    API_KEY: '', 
    S3_LOCATION:'https://ccna-fab-cona-prod-transformation.s3-us-west-2.amazonaws.com/',
    API_ENDPOINT:'https://c5jwo25myj.execute-api.us-west-2.amazonaws.com/prod/new',
    IDENTITY_POOL_ID: '',
    AUTH_PROVIDER_URL: `https://login.microsoftonline.com/548d26ab-8caa-49e1-97c2-a1b1a06cc39c/oauth2/authorize?client_id=9bcbe502-f8d7-4a34-a05a-c034d2e12b00&response_type=id_token&redirect_uri=https://portal.mdm.ko.com&response_mode=fragment&scope=openid&state=12345&nonce=${uuid()}`,
    LOGOUT_URL: '',
    REGION: '',
    IDP_LOGIN: ''
  }
  
};

export function isInEnvironment(envList) {
  if (!window.location.origin || window.location.origin === 'null') {
    // Running unit tests
    return false;
  }

  //check if running from a known environment, set env variables based on what is found
  return envList.includes(window.location.origin);
}

//TODO these should be configurable and not hardcoded
const LOCAL_URIS = [
  'https://localhost:3000',
  'http://localhost:3000',
  'http://ccna-fab-cona-portal-dev-ui.s3-website-us-west-2.amazonaws.com/',
  'https://ccna-fab-cona-portal-dev-ui.s3-us-west-2.amazonaws.com',
  'https://ccna-fab-cona-portal-dev-ui.s3-us-west-2.amazonaws.com/',
  'https://ccna-fab-cona-portal-dev-ui.s3.amazonaws.com/',
  'https://ccna-fab-cona-portal-dev-ui.s3.amazonaws.com',
  'https://d147atg6dv2nig.cloudfront.net',
  'https://d147atg6dv2nig.cloudfront.net/',
  'https://dev.portal.mdm.ko.com'

];

const NONPROD_URIS = [
  'https://ccna-fab-cona-portal-qa-ui.s3.us-west-2.amazonaws.com',
  'https://ccna-fab-cona-portal-qa-ui.s3.us-west-2.amazonaws.com/',
  'https://ccna-fab-cona-portal-qa-ui.s3.amazonaws.com/',
  'https://ccna-fab-cona-portal-qa-ui.s3.amazonaws.com',
  'https://d1zqhcn3tats1q.cloudfront.net',
  'https://d1zqhcn3tats1q.cloudfront.net/',
  'https://qa.portal.mdm.ko.com'
];


const PROD_URIS = [
  'https://d24xpup9ngsht2.cloudfront.net',
  'https://d24xpup9ngsht2.cloudfront.net/',
  'https://portal.mdm.ko.com'
  
];

export function getHost() {
  // Running without browser in unit test
  if (!window.location.origin || window.location.origin === 'null') {
    return 'Unit-Testing!';
  }

  if (isInEnvironment(LOCAL_URIS)) {
    return CONFIG.LOCAL;
  }

  if (isInEnvironment(NONPROD_URIS)) {
    return CONFIG.NONPROD;
  }

  if (isInEnvironment(PROD_URIS)) {
    return CONFIG.PROD;
  }
  throw new Error('Window origin not recognized in API endpoint map.');
}