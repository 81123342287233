
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/explorer.scss";
// pages
import Portal from "views/portal"
import Login from "views/login"

// others
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = window.location.hash ||sessionStorage.getItem('token') ;
      return !window.location.hash || !sessionStorage.getItem('token')? (
        token ?
        <Portal token={token} />
          : <Redirect to='/login.html' />
      ) : (
        <Portal token={token} />
      )
    }}
  />
);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/login.html"
        render={props => <Login {...props} />}
      />
      <Route
        path="/login"
        render={props => <Login {...props} />}
      />
      <PrivateRoute path='/index' token={window.location.hash} component={Portal} />
      <PrivateRoute path='/index.html' token={window.location.hash} component={Portal} />
      <PrivateRoute path='/portal.html' token={window.location.hash} component={Portal} />
      <PrivateRoute path='/portal' token={window.location.hash} component={Portal} />

      <Redirect to="/index.html" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);


