
import React from "react";
import { getHost } from '../services/EnvironmentService';



// index sections
import Explorer from "views/view-components/explorer"
const loginUrl = getHost().AUTH_PROVIDER_URL;

const Portal = props => {
 
  const {
    token,
    } = props;

  if (!token ) {
    if(!sessionStorage.getItem('token')){
      window.location.href = loginUrl
    }
  } 
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <Explorer id_token={token?token:sessionStorage.getItem('token')} />
    </>
  );
}

export default (Portal);
